<template>
    <div :style="setStylePlus()">
        <div class="main" >
            <div class="item">
                <img src="../assets/plane1.png" alt="">
                <div class="title">报站</div>
            </div>
            <div class="item">
                <img src="../assets/plane2.png" alt="">
                <div class="title">天气</div>
            </div>
            <div class="item mt40">
                <img src="../assets/plane3.png" alt="">
                <div class="title">地图</div>
            </div>
            <div class="item mt40">
                <img src="../assets/plane4.png" alt="">
                <div class="title">新闻</div>
            </div>
        </div>
    </div>
</template>

<script>

export default ({
    name: "PLANE",
    props: {
        config: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    }
})

</script>

<style scoped>
.main {
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    flex-wrap: wrap;
    background: #EEEEEE;
}

.item {
    width: 48%;
    height: 620px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mt40 {
    margin-top: 40px;
}

.title {
    font-size: 80px;
    font-family: PingFang SC;
    font-weight: 400;
    margin-top: 64px;
}
</style>
