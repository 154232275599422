<template>
    <div :style="getStyle()" class="messageV1Main">
        <div class="stationName">{{ config.stationName }}</div>
        <!-- lines 为二维数组 -->
        <div class="lineBox" v-for="(line, i) in getLine()" :key="i">
            <div class="head">
                <div style="font-size: 52px;" class="line80">路线名称</div>
                <div style="font-size: 52px;" class="mt20 line80">距离本站</div>
            </div>
            <div class="lines">
                <div v-for="(item, j) in line" :key="j" class="lineItem">
                    <div class="name">
                        <marquee class="line80" behavior="" direction="" v-if="item.stationName.length > 6">{{
                            item.stationName }}</marquee>
                        <div class="line80" v-else>{{ item.stationName }}</div>
                    </div>
                    <div class="space mt20">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default ({
    data() {
        return {
            // 二维数组
            lines: [
                [
                    { stationName: "B990", text: "1站" },
                    { stationName: "B991", text: "1站" },
                    { stationName: "B992", text: "1站" },
                ],
                [
                    { stationName: "B990", text: "1站" },
                    { stationName: "B991", text: "1站" },
                    { stationName: "B992", text: "1站" },
                ],
                [
                    { stationName: "B990", text: "1站" },
                    { stationName: "B991", text: "1站" },
                    { stationName: "高峰专线M3491路", text: "1站" },
                ]
            ],
            line: [
                { stationName: "B990", text: "2站" },
                { stationName: "E37", text: "1站" },
                { stationName: "高峰专线M3491路", text: "1站" },
                { stationName: "392", text: "1站" },
                { stationName: "577", text: "1站" },
                { stationName: "高峰专线M3491路", text: "1站" },
                { stationName: "324", text: "1站" },
                { stationName: "B991", text: "1站" },
                { stationName: "高峰专线M3491路", text: "1站" },
                { stationName: "M345", text: "1站" },
                { stationName: "391", text: "1站" },
                // { stationName: "高峰专线M3491路", text: "1站" },
                // { stationName: "B990", text: "1站" },
                // { stationName: "B991", text: "1站" },
                // { stationName: "高峰专线M3491路", text: "1站" },
            ],
            page: 1, // 翻页页码
            size: 9, // 每页大小
            timer: null, // 翻页器
            duration: 10000, // 翻页间隔 
            station: {},
        }
    },
    methods: {
        getRenderLines() {
            const { size, timer, duration } = this
            if(timer) {
                clearInterval(timer)
                timer = null
            }
            this.timer = setInterval(() => {
                this.page += 1
                if(Math.ceil(this.line.length / size) < this.page) {
                    this.page = 1
                }
            }, duration)
        },
        getLine() {
            var { page, size, line } = this
            var list = line.slice((page - 1 ) * size, page * size)
            // // 每页 3 条切成二维数组
            var res = []
            for(var i = 0; i < list.length; i+=3) {
                res.push(list.slice(i, i + 3))
            }
            return res
        }
    },
    mounted() {
        // var station = new Station({
        //     size: 9,
        //     list: this.line
        // })
        // station.createTimer()
        this.getRenderLines()
        // this.getLine()
    },
    destroyed() {
        // clearInterval(this.timer)
        // this.timer = null
    }
})
</script>

<style scoped>
.messageV1Main {
    background-color: #ffffff;
    padding: 69px 88px;
    box-sizing: border-box;
}

.lineBox {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #eee;
    padding: 50px 0px;
}

.head {
    text-align: center;
    flex: 1;
    font-family: PingFang SC;
    font-weight: bold;
    color: #707070;
}

.lines {
    flex: 4;
    display: flex;
    font-size: 60px;
    align-items: center;
}

.lineItem {
    width: 33.333333%;
    text-align: center;
    font-size: 60px;
    font-family: PingFang SC;
    font-weight: bold;
}

.stationName {
    font-size: 68px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #000000;
    margin-bottom: 10px;
}

.name {
    color: #DE3535;
    height: 80px;
}

.space {
    color: #28EC28;
}

.line80 {
    line-height: 80px;
    height: 80px;
}

.mt20 {
    margin-top: 20px;
}
</style>