<template>
    <div :style="getSwipeStyle()">
        <van-swipe :autoplay="autoplay" indicator-color="white" :touchable="config.trouchable"  :show-indicators="false" @change="swipeChange">
            <van-swipe-item v-for="( item, i ) in config.list" :key="i">
                <div v-if="item.length < 300 && (item.search('.mp4') != -1 || item.search('.MP4') != -1)">
                    <video :id="'video' + i + ''" autoplay="autoplay" muted="muted" @ended="endedHandler"
                        :width="config.width">

                        <source :src="item" type="video/mp4">
                    </video>
                </div>
                <img :src="item" :style="getSwipeItemStyle()" v-else>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant'
export default {
    name: "SWIPE",
    props: {
        config: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        vanSwipe: Swipe,
        vanSwipeItem: SwipeItem
    },
    data() {
        return {
            autoplay: 10000,
            currentIndex: 0
        }
    },
    watch:{
        'config.list'(newlist){
            this.autoplay = 10000
            console.log(newlist,"newlist")
        },
        immediate: true
    },
    methods: {
        swipeChange(index) {
            this.currentIndex = index;
            var list = this.config.list
            if (list[index].search('.mp4') != -1 || list[index].search('.MP4') != -1) {
                this.autoplay = 999999999
                setTimeout(() => {
                    var video = document.getElementById("video" + index)
                    video.addEventListener('error', function (e) {
                        console.log('视频出错了')
                        console.log(e, "twi")
                        video.load();
                        video.play();
                    })
                    video.play()


                }, 1000)
                
            } else {
                this.autoplay = 10000
            }


        },
        endedHandler() {
            var video = document.getElementById("video" + this.currentIndex)
            if (this.config.list && this.config.list.length == 1) {
                video.play()
            } else {
                video.pause();
                this.autoplay = 10000
            }
        },
        getSwipeStyle() {
            var { width, height, zIndex, x, y } = this.config
            return {
                width: width + 'px',
                height: height + 'px',
                position: "fixed",
                zIndex: zIndex,
                top: y + 'px',
                left: x + 'px'
            }
        },
        getSwipeItemStyle() {
            var { width, height } = this.config
            return {
                width: width + 'px',
                height: height + 'px'
            }
        }
    },
    created() {
        let list = this.config.list
        this.autoplay = 10000
        if (list && list.length >= 0 && list[0].length <= 200 && (list[0].search('.mp4') != -1 || list[0].search('.MP4') != -1)) {
            // this.autoplay = 999999999
           
            // alert(list && list.length >= 0 && (list[0].search('.mp4') != -1 || list[0].search('.MP4') != -1))
        } 
    }
}
</script>

<style scoped>
.swipe {
    height: 560px;
    position: relative;

}
</style>