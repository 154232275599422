import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index/index.vue'
Vue.use(VueRouter)

// 创建实例对象
const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: Index
        },
        {
            path: '/75',
            component: () => import('../pages/screen/screen75.vue')
        },
        {
            path: '/55',
            component: () => import('../pages/screen/screen55.vue')
        },
        {
            path: '/qrcode',
            component: () => import('../pages/qrcode/index.vue')
        },
        {
            path: '/news',
            component: () => import('../pages/news/index.vue')
        },
        {
            // 静态资源
            path: '/test',
            component: () => import('../pages/test/index.vue')
        },
        {
            path: '/plane',
            component: () => import('../pages/plane/test.vue')
        },
        {
            path: '/nonTouchPlane',
            component: () => import('../pages/plane/nonTouch.vue')
        },
        // 测试路由
        {
            path: '/cs',
            component: () => import('../pages/ces/ces.vue')
        },
        {
            path: '/preview',
            component: () => import('../pages/preview/index.vue')
        },
        // 非触摸直接显示多线路报站屏
        {
            path: '/nonTouchMoreLine',
            component: () => import('../pages/nonTouchMoreLine/index.vue')
        },
        // 非触摸直接显示多线路报站屏 无轮播
        {
            path: '/nonTouchMoreLineNonSwipe',
            component: () => import('../pages/nonTouchMoreLine/nonSwipe.vue')
        },
        // 横屏非触摸直接显示多线路报站屏 无轮播
        {
            path: '/HNonSwipeMoreLine',
            component: () => import('../pages/nonTouchMoreLine/HNonSwipe.vue')
        },
        // 横屏非触摸直接显示多线路报站屏 有轮播
        {
            path: '/HSwipe',
            component: () => import('../pages/nonTouchMoreLine/Hindex.vue')
        },
        {
            path: "/2lines",
            component: () => import('../pages/nonTouchMoreLine/2lines.vue')
        },

        
        // 前海腾讯大厦 2024-06-17 演示
        {
            path: '/qianhaiTouch',
            component: () => import('../pages/qianhai/touch.vue')
        },
        {
            path: '/qianhaiNoTouch',
            component: () => import('../pages/qianhai/noTouch.vue')
        },

         //宁波镇海 2024-06-17 演示
         {
            path: '/ningboTouch',
            component: () => import('../pages/ningbo/touch.vue')
        },
        {
            path: '/ningboNoTouch',
            component: () => import('../pages/ningbo/noTouch.vue')
        },

        // 404 页面
        {
            path: '/*',
            component: () => import('../pages/404.vue')
        },
    ]
})

export default router


