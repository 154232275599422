import instance from "./request.js";

var lineData = []

function getParams(field) {
    var url = decodeURIComponent(window.location.href)
    var str = field + "="
    if (url.search(str) > -1) {
        var system = url.split(str)[1]
        return system.split("&")[0]
    }
    return ""
}

// 获取线路数据,初始化
function initLine() {
    const busstopId = getParams("otherId")
    instance({ url: "/nanchang/nanchang/lineInfo/" + busstopId }).then(res => {
        console.log(res,'busstopId===');
        
        if (!res) return
        const stationName = getParams("stationName")
        res.data = res.data.map(item => {
            item.stationList.map(station => {
                if (station.stationName == stationName) {
                    item.order = station.stationOrder
                }
            })
            return item
        })
        const deviceCode = getParams('deviceCode')
        instance({ url: `/project/public/getH5Config/${deviceCode}` }).then(stationInfo => {
            console.log(stationInfo, '数据***---===');
        }).catch(err => {
            console.log(err, '数据***---===');
        })

        instance({ url: "/nanchang/nanchang/realBus/" + busstopId }).then(real => {
            lineData = dataConcat(res.data, real.data)
            console.log("站点信息接口成功")
            setTimeToget(3000)
        }).catch(err=>{
            console.log("站点信息接口失败")
        })

    }).catch(err=> {
        console.log("站点信息接口失败")
    })

}

function dataConcat(source, target) {
    let tem = []
    const notPass = [1, 2, -5]
    target.map(line => {
        if (notPass.indexOf(line.lineState) != -1) {
            return
        } else {

            const find = source.find(item => item.lineName == line.lineName)
            if (find) {
                const stl = {
                    ...find,
                    ...line
                }
                stl.distanceInfo = getDistanceInfo(stl)
                stl.travelTime = getTravelTime(stl)
                tem.push(stl)
            }
        }
    })
    return tem
}

function setTimeToget(timeout) {
    const busstopId = getParams("otherId")
    instance({ url: "/nanchang/nanchang/realBus/" + busstopId }).then(res => {
        lineData = dataConcat(lineData, res.data)
        setTimeout(() => {
            setTimeToget(timeout)
        }, timeout);
    }).catch(err=>{
        console.log('南昌站点信息接口成功',err);
    })

}



function getLineData() {
    return lineData
}


function getDistanceInfo(params) {
    const status = {
        "-1": "等待发车",
        "-2": "暂时失联",
        "-3": "末班已过",
        "-4": "首班未发",
        "-8": "今日无班次",
    }
    const stations = params
    // console.log(stations, '数据***---===');
    if (stations.lineState == 0) {
        const bus = stations.realtimeBus[0]
        // console.log(bus, '数据***---===');
        if (bus.destCount == 0 && bus.busState == 1) {
            return "已到站"
        } else if (bus.destCount == 0 && bus.busState == 0) {

            return "即将到站"
        } else {
            return bus.destCount + "站"
        }
    } else {
        return status[stations.lineState]
    }
}

function getTravelTime(params) {
    const stations = params
    if (!stations.realtimeBus || stations.realtimeBus.length == 0) {
        return "无信息"
    } else {
        const bus = stations.realtimeBus[0]
        if (bus.travelTime != 0) {
            return Math.ceil(bus.travelTime / 60) + "分钟"
        } else {
            return ""
        }

    }
}



export { initLine, getLineData }