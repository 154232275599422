<template>
    <div :style="getSwipeStyle()">
        <van-notice-bar :color="config.color" :scrollable="true" :background="config.background" :style="{height: config.height + 'px', lineHeight: config.height + 'px'}">{{config.text}}
            <span slot="left-icon" style="font-size: 36px;">公告：</span>
        </van-notice-bar>
    </div>
</template>

<script>
import { NoticeBar } from 'vant'
export default {
    name: "SWIPE",
    props: {
        config: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        vanNoticeBar: NoticeBar
    },
    data() {
        return {

        }
    },
    methods: {
        getSwipeStyle() {
            var { width, height, zIndex, x, y } = this.config
            return {
                width: width + 'px',
                height: height + 'px',
                position: "fixed",
                zIndex: zIndex,
                top: y + 'px',
                left: x + 'px'
            }
        },
        getSwipeItemStyle() {
            var { width, height } = this.config
            return {
                width: width + 'px',
                height: height + 'px'
            }
        }
    }
}
</script>
  
<style scoped>
/deep/ .van-notice-bar__content {
    font-size: 36px !important;
}
</style>
  