import axios from 'axios'
const instance = axios.create({
    baseURL: process.env.VUE_APP_Server,
    timeout: 10000,
});
instance.interceptors.request.use(function (config) {
    config.url=config.baseURL+config.url
    return config
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {

    if (response.status == 200) {
        return response.data;
    } else {
        return false;
    }
    // 对响应数据做点什么

}, function (error) {
    console.log(error,"error")
    if(error.response){
        return  Promise.reject(error.response);
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});
export default instance