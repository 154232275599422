<template>
    <div :style="getStyle()" class="messageV1Main">
        <div class="stationName">{{ stations.stationName }}</div>
        <div class="lineBox">
            <div class="head">
                <div>路线名称</div>
                <div>距离本站</div>
            </div>
            <div class="lines">
                <div v-for="(item, i) in line" :key="i" class="lineItem">
                    <div class="name">{{ item.stationName }}</div>
                    <div class="space">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    props: {
        config: {
            type: Object,
            default: () => { }
        },
        stations: {
            type: Object,
            default: () => { }
        }
    },
    data() {

        return {
            line: [
                { stationName: "B990", text: "1站" },
                { stationName: "B991", text: "1站" },
                { stationName: "B992", text: "1站" },
                { stationName: "B993", text: "1站" },
            ]
        }
    },
    methods: {

    }
})
</script>

<style scoped>
.messageV1Main {
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
}

.lineBox {
    display: flex;
    align-items: center;
    line-height: 55px;
}

.head {
    text-align: center;
    flex: 1;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #707070;
}

.lines {
    flex: 4;
    display: flex;
    align-items: center;
}

.lineItem {
    width: 25%;
    text-align: center;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
}

.stationName {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #000000;
}

.name {
    color: #DE3535;
}

.space {
    color: #28EC28;
}
</style>