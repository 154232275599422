<template>
    <div class="back" v-bind="$attrs">
        <img src="@/assets/back-icon.png" class="icon-back">
        <span>
            返回
        </span>
    </div>
</template>
<script>
export default {
    name: "backNav"
}
</script>


<style  scoped>
.back {
    position: absolute;
    left: 20px;
    top: 20px;
    background: #D5DEFC;
    width: 88px;
    height: 88px;
    border: 1px solid #0082C5;
    border-radius: 8px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.icon-back {
    width: 44px;
    height: 44px;
}
</style>