<template>
    <div :style="getStyle()" class="messageV1Main">
        <div class="stationName">{{ config.stationName }}</div>
        <div class="lineBox">
            <div class="head">
                <div class="flex1-center">路线名称</div>
                <div class="flex1-center">距离本站</div>
            </div>
            <div class="lines">
                <div v-for="(item, i) in line" :key="i" class="lineItem">
                    <div class="flex1-center" style="color: #DE3535">
                        <!-- 站点名长度大于 6 则滚动 -->
                        <marquee behavior="" direction="" v-if="item.stationName.length > 6">{{ item.stationName }}</marquee>
                        <span v-else>{{ item.stationName }}</span>
                    </div>
                    <div class="flex1-center" style="color: #28EC28">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    data() {
        return {
            line: [
                { stationName: "B990", text: "1站" },
                { stationName: "B991", text: "1站" },
                { stationName: "B992", text: "1站" },
                { stationName: "B993", text: "1站" },
                { stationName: "B995", text: "1站" },
                { stationName: "B996", text: "1站" },
                { stationName: "B997", text: "1站" },
                { stationName: "B998", text: "1站" },
                { stationName: "高峰专线M3491路", text: "1站" },
            ]
        }
    },
    methods: {

    }
})
</script>

<style scoped>
.messageV1Main {
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
}

.lineBox {}

.head {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #707070;
}

.lines {
    /* flex: 4;
    display: flex;
    align-items: center; */
}

.lineItem {
    display: flex;
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 800;
}

.stationName {
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #000000;
}

.flex1-center {
    flex: 1;
    text-align: center;
    line-height: 80px;
}
</style>