<template>
    <div class="main" v-if="visabled">
        <backNav @click.native="visabled = false" class="backTran" />
        <div class="fite ">
            <div class="f30 ">
                <span>{{ getWeek('today') }}</span>
                <!-- <span>{{ forecasts[0].date }}</span> -->
            </div>
            <div class="f30 padding35 flexR">
                <div>空气湿度</div>
                <div class="humidity-box" :style="`background:${humidityStyle};`">{{ todayWeather.humidity+"%" || "" }}</div>
            </div>
            <div class="f30 flexR padding35">
                <img src="../assets/weather_local.png" alt="" class="icon-cla">
                <div>{{ city||"" }}</div>
            </div>
        </div>
        <div class="large">
            {{ forecasts[0]&&forecasts[0].dayTemp }}
            <div class="dot"></div>
            <div class="dayWeather">
                {{ forecasts[0]?forecasts[0].dayWeather + "&nbsp;&nbsp;&nbsp;&nbsp;" + forecasts[0].nightTemp + "~" +
                    forecasts[0].dayTemp + "℃":"" }}
            </div>
        </div>
        <div class="chartBox">
            <div class="title">四天天气预报</div>
            <div class="weatherList">
                <div v-for="(item, i) in forecasts" :key="i" class="chartBoxItem">
                    <div class="mb20">{{ getWeek(i, item.week) }}</div>
                    <div class="mb20">{{ item.date ? item.date.substring(5) : '' }}</div>
                    <div class="mb20">{{ item.dayWeather }}</div>
                    <div class="mb20">{{ item.nightTemp + "~" + item.dayTemp + "℃" }}</div>
                    <div class="mb20">{{ getWind(item) }}</div>
                    <img :src="getIcon(item)" alt="">
                </div>
            </div>
            <div id="chart"></div>
        </div>
    </div>
</template>

<script>
import getWeatherIcon from "./weatherIcon.js";
/* eslint-disable */
import backNav from "@/components/backNav.vue";


export default ({
    name: "WEATHER",
    components: {
        backNav
    },
    data() {
        return {
            max: 30,
            min: 14,
            forecasts: [],
            city: '深圳市',
            todayWeather: {},
            visabled: false
        }
    },
    computed:{
        humidityStyle(){
            const {humidity}=this.todayWeather
            if(humidity>=60||humidity<=30){
                return "#DF0505"
            }
            return "#53C459"
            
        }
    },
    methods: {
        openPopup() {
            this.visabled = true
            this.getData()
            this.drawLine()
        },
        getData() {
            var that = this
            var city = that.getUrlField('city')
            that.city = city ? city : '深圳市'
            AMap.plugin('AMap.Weather', function () {
                var weather = new AMap.Weather();
                //查询实时天气信息, 查询的城市到行政级别的城市，如朝阳区、杭州市
                weather.getLive(that.city, function (err, data) {
                    if (!err) {
                        that.todayWeather = data
                    }
                });
                //未来4天天气预报
                weather.getForecast(that.city, function (err, data) {
                    console.log("err",err,"data",data)
                    if (err) { return; }
                   
                    that.forecasts = data.forecasts
                    console.log(data.forecasts)

                });
            });
        },
        getDayWeather() {
            var forecasts = this.forecasts
            try {
                var item = forecasts[0]
                if (item) {
                    return `${item.dayWeather}/${item.nightWeather} ${item.nightTemp}° ~ ${item.dayTemp}°C`
                }
            } catch (error) {
                return ""
            }

        },
        getWind(item) {
            let wind = ""
            if (item.dayWindDir == "无风向") {
                wind += ""
            } else {
                wind += item.dayWindDir
            }
            wind += "     " + item.dayWindPower + "级"
            return wind
        },
        drawLine() {
            var max = [], min = [];
            for (var i = 0, dayWeather; i < this.forecasts.length; i++) {
                dayWeather = this.forecasts[i]
                max.push(dayWeather.dayTemp)
                min.push(dayWeather.nightTemp)
            }
            console.log("min",min,"max",max)
            let maxlen=max.length
            let minlen=min.length
            var max_max = maxlen?Math.max(...max):0
            var max_min = maxlen?Math.min(...max):0
            var min_max = minlen?Math.max(...min):0
            var min_min = minlen?Math.min(...min):0
            console.log("max_max",max_max,"max_min",max_min,"min_max",min_max,"min_min",min_min)
            var $max = max_max > min_max ? max_max : min_max
            var $min = max_min > min_min ? min_min : max_min
            console.log('%c==== 111 start====', 'color: red')
            console.log($max, $min)
            console.log('%c==== 111 end====', 'color: red')
            this.$nextTick(() => {

                var chartDom = document.getElementById('chart');
                console.log(chartDom, '数据***---===');
                // return
                var myChart = echarts.init(chartDom);
                var option = {
                    xAxis: {
                        type: 'category',
                    },
                    grid: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    },
                    yAxis: {
                        show: false,
                        min: $min - 3,
                        max: $max + 3
                    },
                    series: [
                        {
                            data: max,
                            type: 'line',
                            color: "#fff",
                            label: {
                                show: true,
                                position: 'top',
                                fontSize: 30,
                                color: "#000"
                            },
                            symbolSize: 15,
                            lineStyle: {
                                width: 4
                            }
                        },
                        {
                            data: min,
                            type: 'line',
                            color: "#fff",
                            label: {
                                show: true,
                                position: 'bottom',
                                fontSize: 30,
                                color: "#000",
                                width: 10
                            },
                            symbolSize: 15,
                            lineStyle: {
                                width: 4
                            }
                        }
                    ]
                };
                myChart && myChart.setOption(option);
            })
        },
        getWeek(i, week) {
            if (i < 2) {
                return i == 0 ? '今天' : '明天'
            } else {
                var weeks = ['', '周一', '周二', '周三', '周四', '周五', '周六', '周日']
                if (i == 'today') {
                    try {
                        return this.forecasts[0].date + ' ' + weeks[this.forecasts[0].week]
                    } catch (error) {
                        return ''
                    }
                }
                return weeks[week]
            }
        },
        getIcon(item) {
            return getWeatherIcon(item.dayWeather)
        }
    },
    mounted() {
        this.getData()

    }
})

</script>

<style scoped>
.main {
    height: 1300px;
    background-image: url('../assets/weather.png');
    background-size: cover;
    padding: 40px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    z-index: 9999999;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
}

.backTran {
    background: transparent !important;

}

.flexR {
    display: flex;
    align-items: center;
}

.fite {
    position: absolute;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}



.f30 {
    font-size: 30px;
    color: #000;
    font-family: PingFang SC Heavy-Regular, PingFang SC Heavy;
    font-weight: 600;
}

.padding35 {
    padding-top: 35px;
}

.icon-cla{
    width: 38px;
    height: 38px;
    margin-right: 10px;
}


.today_weather {
    font-size: 100px;
    font-family: PingFang SC;
    font-weight: 400;
    margin-right: 30px;
}

.weatherList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.large {
    position: absolute;
    top: 217px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 187px;
    text-align: center;
}

.dot {
    position: absolute;
    right: -30px;
    top: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #000;
}

.today {
    color: #fff;
    padding: 70px 0px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
}

.dayWeather {
    font-size: 38px;
}

/* 折线图区域 */
.chartBox {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 532px;
    width: 90%;
    left: 5%;
    color: #000;
    padding: 20px;
    box-sizing: border-box;
    font-size: 24px;
}

.chartBoxItem {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#chart {
    width: 100%;
    height: 300px;
    margin-top: 30px;
}

.mb20 {
    margin-bottom: 15px;
    color: #000;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
}
.humidity-box{
    width: 80px;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    margin-left: 15px;
}
.title{
    font-size: 32px;
    font-weight: bolder;
}
</style>
