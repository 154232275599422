<template>
  <div id="app">
    <!-- 非触摸竖屏 -->
    <!-- <vSwipe :config="swipeTest"></vSwipe>
    <noticeBar :config="noticeBarTest"></noticeBar>
    <Map :config="mapTest"></Map>
    <messageV1 :config="messageV1Test"></messageV1> -->
    <!-- 非触摸横屏 -->
    <!-- <vSwipe :config="swipeTest1"></vSwipe>
    <noticeBar :config="noticeBarTest1"></noticeBar>
    <messageV2 :config="messageV1Test1"></messageV2> -->
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable */
import vSwipe from './components/swipe.vue'
import noticeBar from './components/noticeBar.vue'
import Map from './components/map.vue'
import messageV1 from './components/messageV1.vue'
import messageV2 from './components/messageV2.vue'
import {initLine  } from "./util/stationData.js";

export default {
  name: 'App',
  components: {
    vSwipe,
    noticeBar,
    Map,
    messageV1,
    messageV2
  },
  data() {
    return {
      swipeTest: {
        width: 1080,
        height: 560,
        imgList: [
          "https://img1.baidu.com/it/u=413643897,2296924942&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500",
          "https://img2.baidu.com/it/u=45841977,3664621913&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500"
        ],
        x: 0,
        y: 0,
        zIndex: 1000
      },
      noticeBarTest: {
        width: 1080,
        color: "#ffffff",
        background: "rgba(0,0,0,0.4)",
        x: 0,
        y: 520,
        zIndex: 1001,
        text: "在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。"
      },
      mapTest: {
        width: 1080,
        height: 1360,
        x: 0,
        y: 560,
        zIndex: 1001,
        lng: 113.894847,
        lat: 22.533367
      },
      messageV1Test: {
        stationName: "这里是站点名称",
        width: 1080,
        height: 'auto',
        x: 0,
        y: -1,
        zIndex: 1001,
        lng: 113,
        lat: 24
      },

      // 横屏
      swipeTest1: {
        width: 1440,
        height: 1080,
        imgList: [
          "https://img1.baidu.com/it/u=413643897,2296924942&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500",
          "https://img2.baidu.com/it/u=45841977,3664621913&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500"
        ],
        x: 0,
        y: 0,
        zIndex: 1000
      },
      noticeBarTest1: {
        width: 1440,
        height: 64,
        color: "#ffffff",
        background: "rgba(0,0,0,0.5)",
        x: 0,
        y: 1016,
        zIndex: 1001,
        text: "在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。"
      },
      messageV1Test1: {
        stationName: "这里是站点名称",
        width: 480,
        height: 'auto',
        x: 1440,
        y: 0,
        zIndex: 1001,
        lng: 113,
        lat: 24
      }
    }
  },
  methods: {
      
  }, 
  mounted(){
    initLine()
  }
}
</script>

<style scoped>
.bgf {
  background-color: #ffffff;
}
</style>
