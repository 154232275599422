
const arrIcon = [
    { name: "晴", path: "晴" },
    { name: "少云", path: "晴" },
    { name: "晴间多云", path: "晴转多云" },
    { name: "多云", path: "晴转多云" },
    { name: "阴", path: "晴" },
    { name: "有风", path: "大风" },
    { name: "平静", path: "晴" },
    { name: "微风", path: "大风" },
    { name: "和风", path: "大风" },
    { name: "清风", path: "大风" },
    { name: "强风/劲风", path: "大风" },
    { name: "疾风", path: "大风" },
    { name: "大风", path: "大风" },
    { name: "烈风", path: "大风" },
    { name: "风暴", path: "大风" },
    { name: "狂爆风", path: "大风" },
    { name: "飓风", path: "大风" },
    { name: "热带风暴", path: "大风" },
    { name: "霾", path: "晴" },
    { name: "中度霾", path: "晴" },
    { name: "重度霾", path: "晴" },
    { name: "严重霾", path: "晴" },
    { name: "阵雨", path: "雷阵雨" },
    { name: "雷阵雨", path: "雷阵雨" },
    { name: "雷阵雨并伴有冰雹", path: "雷阵雨" },
    { name: "小雨", path: "小雨" },
    { name: "中雨", path: "小雨" },
    { name: "大雨", path: "大雨" },
    { name: "暴雨", path: "大雨" },
    { name: "大暴雨", path: "大雨" },
    { name: "特大暴雨", path: "大雨" },
    { name: "强阵雨", path: "大雨" },
    { name: "强雷阵雨", path: "大雨" },
    { name: "极端降雨", path: "大雨" },
    { name: "毛毛雨/细雨", path: "小雨" },
    { name: "雨", path: "小雨" },
    { name: "小雨-中雨", path: "小雨" },
    { name: "中雨-大雨", path: "大雨" },
    { name: "大雨-暴雨", path: "大雨" },
    { name: "暴雨-大暴雨", path: "大雨" },
    { name: "大暴雨-特大暴雨", path: "大雨" },
    { name: "雨雪天气", path: "雨雪" },
    { name: "雨夹雪", path: "雨雪" },
    { name: "阵雨夹雪", path: "雨雪" },
    { name: "冻雨", path: "雨雪" },
    { name: "雪", path: "晴" },
    { name: "阵雪", path: "晴" },
    { name: "小雪", path: "晴" },
    { name: "中雪", path: "晴" },
    { name: "大雪", path: "晴" },
    { name: "暴雪", path: "晴" },
    { name: "小雪-中雪", path: "晴" },
    { name: "中雪-大雪", path: "晴" },
    { name: "大雪-暴雪", path: "晴" },
    { name: "浮尘", path: "晴" },
    { name: "扬沙", path: "晴" },
    { name: "沙尘暴", path: "晴" },
    { name: "强沙尘暴", path: "晴" },
    { name: "龙卷风", path: "大风" },
    { name: "雾", path: "雾" },
    { name: "浓雾", path: "雾" },
    { name: "强浓雾", path: "雾" },
    { name: "轻雾", path: "雾" },
    { name: "大雾", path: "雾" },
    { name: "特强浓雾", path: "雾" },
    { name: "热", path: "晴" },
    { name: "冷", path: "晴" },
    { name: "未知", path: "晴" },

]

export default function getWeatherIcon(name) {
    const icon = arrIcon.find(item => item.name == name)
    return require(`../assets/weatherIcon/${icon.path}.png`)
}