/* eslint-disable - test */
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './routes/index.js'
import defaultImg from "./assets/default.png"
import "./assets/css/default.css"
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.mixin({
  props: {
    config: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    // 判断调用系统
    // 物联平台 system = 16iot
    getParams(field) {
      var url = decodeURIComponent(window.location.href)
      var str = field + "="
      if (url.search(str) > -1) {
        var system = url.split(str)[1]
        return system.split("&")[0]
      }
      return ""
    },
    // 预览模式
    previewHandler() {
      var type = this.getParams("type")
      if (type == "preview") {
        window.addEventListener('message', event => {
          // 项目部署位置
          // if (event.origin == "http://192.168.1.101") {
          //   var res = event.data
          //   if (res.type == "station" && this.stationConfig) {
          //     // 清空站点
          //     this.$set(this.stationConfig, "title", "多设备批量发布节目")
          //   } else if (res.type == "noticeBar") {
          //     // 公告
          //     this.$set(this.bottomConfig, "text", res.data)
          //     this.$set(this.bottomConfig, "roll", 1)
          //   } else if (res.type == "swipe" && this.swipeConfig) {  
          //     // 轮播
          //     this.$set(this.swipeConfig, "list", res.data)
          //   }
          // }
          var res = event.data
          console.log(event.data, '预览模式***---===');

          if (res.type == "station" && this.stationConfig) {
            // 清空站点
            this.$set(this.stationConfig, "title", "多设备批量发布节目")
          }
          if (res.type == "noticeBar") {
            // 公告
            this.$set(this.bottomConfig, "text", res.data)
            this.$set(this.bottomConfig, "roll", 1)
          }
          if (res.type == "swipe" && this.swipeConfig) {
            // 轮播
            this.$set(this.swipeConfig, "list", res.data)
          }
        }, false);
      }
    },
    // 正式版本
    releasesHandler() {
      var version = this.getParams("version")
      console.log("releaseHandler-----------------------------------")
      if (version == "releases") {
        if (this.stationConfig) {
          this.stationConfig.title = this.getParams("stationName")
        }
        if (this.swipeConfig) {
          this.$set(this.swipeConfig, "list", [defaultImg])
        }
        if (this.mapConfig) {
          this.$set(this.mapConfig, "city", this.getParams("city"))
          this.$set(this.mapConfig, "log", this.getParams("log"))
          this.$set(this.mapConfig, "lat", this.getParams("lat"))
        }

        try {

          this.androidADResourceToWebRender()
        } catch (error) {

        }
        setTimeout(() => {
          try {
            //获取终端图片视频轮播资源
            var res = window.JsCallJava.jsCallAndroidADResource()

            var resText = window.JsCallJava.jsCallAndroidTextResource()
          } catch (error) {
  
          }
          if (resText) {
            try {
              var textList = JSON.parse(resText)['words']
              console.log("jsCallAndroidTextResource:资源数据" + textList)
              if (textList.length == 0) {
                this.$set(this.bottomConfig, "text", null)
                this.$set(this.bottomConfig, "roll", 0)
              } else {
                let space = ""
                for (let index = 0; index < 6; index++) {
                  space += "&nbsp;";
                }
                this.$set(this.bottomConfig, "text", textList.join(space))
                this.$set(this.bottomConfig, "roll", 1)
              }
            } catch (error) {
              console.log("jsCallAndroidTextResource:错误" + error)
            }
          }
          if (res) {
            try {
              var pathList = JSON.parse(res)['pathList']
              console.log("jsCallAndroidADResource:资源数据" + pathList)
            
              this.$set(this.swipeConfig, "list", pathList)
            } catch (error) {
              console.log("jsCallAndroidADResource:错误" + error)
            }
          }
        }, 3000)
      }
    },

    androidADResourceToWebRender() {
      
      window.addEventListener("androidADResourceToWeb", res => {
        var detail = res.detail
        var dataKey = "pathList"
        
        console.log("来过androidADResourceToWeb,有没有传输数据detail:",detail)
        if (detail[dataKey] && detail[dataKey].length) {
          var swipeList = []
          for (var i = 0; i < detail[dataKey].length; i++) {
            var item = detail[dataKey][i];
            if (item.search(".part") == -1) {
              swipeList.push(item)
            }
          }
          // 轮播
          if (this.swipeConfig) {
            if (swipeList.length) {
              this.$set(this.swipeConfig, "list", swipeList)
            } else {
              this.$set(this.swipeConfig, "list", [defaultImg])
            }
          }
        } else {
          // 轮播
          if (this.swipeConfig) {
            this.$set(this.swipeConfig, "list", [defaultImg])
          }
        }
      })

      window.addEventListener("sendNotice", res => {
        var detail = res.detail
        // 公告
        if (this.bottomConfig) {
          if (detail.words.length == 0) {
            this.$set(this.bottomConfig, "text", null)
            this.$set(this.bottomConfig, "roll", 0)
          } else {
            let space = ""
            for (let index = 0; index < 6; index++) {
              space += "&nbsp;";
            }
            this.$set(this.bottomConfig, "text", detail.words.join(space))
            this.$set(this.bottomConfig, "roll", 1)
          }
        }
      })
    },
    // 绑定 style 属性
    getStyle() {
      var { width, height, zIndex, x, y } = this.config
      var style = {
        width: width + 'px',
        height: height == 'auto' ? 'auto' : height + 'px',
        position: "fixed",
        zIndex,
        top: y + 'px',
        left: x + 'px'
      }
      if (y == "-1" || y == -1) {
        delete style.top
        style.bottom = "0px"
      }
      return style
    },
    setStylePlus() {
      var { width = 1080, height = 200, zIndex = 100, x = 0, y = 0, background, color } = this.config
      var style = {
        width: width + 'px',
        height: height + 'px',
        position: "fixed",
        zIndex: zIndex,
        top: y + 'px',
        left: x + 'px'
      }
      if (background) style.background = background
      if (color) style.color = color
      return style
    },
    // 获取url上某个字段的属性值
    getUrlField(field) {
      try {
        var url = decodeURIComponent(window.location.href)
        return url.split(field + "=")[1].split("&")[0]
      } catch (error) {
        return null
      }
    },

  }
})

// {"state":"路已到站","busOrder":3,"msgType":"bus_info","busLine":"M355"}
//
window.appToWeb = function (detail) {
  router.push("/")
  try {
    var myEvent = new CustomEvent("appToWebEvent", { detail: JSON.parse(detail) })
    window.dispatchEvent(myEvent);
  } catch (error) {
    console.log(error)
  }
}
// 获取车辆站点信息的自定义事件
window.notifyBusPosition = function (detail) {
  try {
    var myEvent = new CustomEvent("notifyBusPosition", { detail: JSON.parse(detail) })
    window.dispatchEvent(myEvent);
  } catch (error) {
    console.log(error)
  }
}

// 获取 app 推送的轮播数据， 包括图片和视频的自定义事件
window.androidADResourceToWeb = function (detail) {
  try {
    var myEvent = new CustomEvent("androidADResourceToWeb", { detail })
    window.dispatchEvent(myEvent);
  } catch (error) {
    console.log(error)
  }
}

// 获取 app 推送的公告文本的自定义事件
window.sendNotice = function (detail) {
  try {
    var myEvent = new CustomEvent("sendNotice", { detail })
    window.dispatchEvent(myEvent);
  } catch (error) {
    console.log(error)
  }
}

// 用户未触摸2分钟后刷新页面，供终端使用
var userTouchTimer = null
window.userTouch = function () {
  
  if (userTouchTimer) {
    clearTimeout(userTouchTimer)
    userTouchTimer = null
  }
  userTouchTimer = setTimeout(() => {
   
    console.log("用户未触摸2分钟后刷新页面")
    if(window.location.reload){
      window.location.reload(true)
    }else if(window.location.replace){
      location.replace(window.location.href)
    }else{
      window.history.go(0)
    }
    
  }, 120000)
}


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
